import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


const styles = theme => ({
  input: {
    display: 'flex',
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    bottom: 5,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  defaultOptions: PropTypes.arrayOf(PropTypes.object),
  defaultSelected: PropTypes.oneOfType([PropTypes.object]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  defaultOptions: null,
  defaultSelected: null,
  placeholder: 'Search',
  disabled: false,
};

function NoOptionsMessage(props) {
  const { selectProps, innerProps, children } = props; // eslint-disable-line react/prop-types
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) { // eslint-disable-line react/prop-types
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  /* eslint-disable react/prop-types */
  const {
    selectProps,
    innerProps,
    children,
    innerRef,
  } = props;
  /* eslint-enable react/prop-types */
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
    />
  );
}


function Option(props) {
  /* eslint-disable react/prop-types */
  const {
    isFocused,
    innerProps,
    children,
    innerRef,
    isSelected,
  } = props;
  /* eslint-enable react/prop-types */
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const { selectProps, innerProps, children } = props; // eslint-disable-line react/prop-types
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props) {
  const { selectProps, innerProps, children } = props; // eslint-disable-line react/prop-types
  return (
    <Typography
      className={selectProps.classes.singleValue}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function ValueContainer(props) {
  const { selectProps, children } = props; // eslint-disable-line react/prop-types
  return (
    <div className={selectProps.classes.valueContainer}>
      {children}
    </div>);
}

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(option) {
    const { onChange } = this.props;
    if (option && option.value) {
      this.setState({
        selected: option,
      }, () => onChange(option.value));
    } else {
      this.setState({
        selected: null,
      }, () => onChange(''));
    }
  }

  render() {
    const {
      classes,
      defaultOptions,
      loadOptions,
      defaultSelected,
      placeholder,
      disabled,
    } = this.props;
    const { selected } = this.state;

    let value = selected;
    if (defaultSelected) {
      value = defaultSelected;
    }

    return (
      <AsyncSelect
        classes={classes}
        defaultOptions={defaultOptions}
        components={components}
        value={value}
        onChange={this.handleChange}
        loadOptions={loadOptions}
        placeholder={placeholder}
        onBlurResetsInput={false}
        isDisabled={disabled}
      />
    );
  }
}

Autocomplete.propTypes = propTypes;
Autocomplete.defaultProps = defaultProps;
export default withStyles(styles)(Autocomplete);
