export default {
  palette: {
    primary: {
      main: '#2d95e5',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#ff3885',
    },
    background: {
      default: '#f9f9f9',
    },
  },
  drawer: {
    width: 240,
  },
  typography: {
    headline: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    useNextVariants: true,
  },
  overrides: {
    MuiCollapse: {
      entered: {
        overflow: 'visible',
        height: 'auto',
        '.overflowVisible > &': {
          overflow: 'visible',
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#2d95e5',
      },
    },
  },
};
