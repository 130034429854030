import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import PreviewEmail from '../containers/PreviewEmail/PreviewEmail';
import Resume from '../containers/Resume/Resume';
import Lighthouse from '../containers/Lighthouse/Lighthouse';
import BrokenLinks from '../containers/BrokenLinks/BrokenLinks';
import SpeedTest from '../containers/SpeedTest/SpeedTest';
import GaTest from '../containers/GaTest/GaTest';
import WpScan from '../containers/WpScan/WpScan';
import Screenshots from '../containers/Screenshots/Screenshots';
import ProAudit from '../containers/ProAudit/ProAudit';
import { QSParse } from '../utils/urlParse';


const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const PreviewPage = ({ history, classes }) => {
  const values = QSParse(history.location.search);

  return (
    <div className={classes.list}>
      <PreviewEmail url={values.url} />
      <Resume url={values.url} />
      <Lighthouse url={values.url} />
      <BrokenLinks url={values.url} />
      <SpeedTest url={values.url} />
      <GaTest url={values.url} />
      <WpScan url={values.url} />
      <Screenshots url={values.url} />
      <ProAudit url={values.url} />
    </div>
  );
};

PreviewPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(PreviewPage);
