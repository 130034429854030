import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { Formik } from 'formik';
import TextField from './TextField';
import AutocompleteField from './AutocompleteField';
import SelectField from './SelectField';
import UploadField from './UploadField';
import { getFormInitialValues, getFormValidations } from './utils';

const styles = theme => ({
  wrapper: {
    marginBottom: '30px',
  },
  noMargin: {
    marginBottom: 0,
  },
  reset: {
    marginRight: theme.spacing.unit * 2,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    validateAs: PropTypes.string.isRequired,
    defaultVal: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    customProps: PropTypes.oneOfType([PropTypes.object]),
  })).isRequired,
  submitLabel: PropTypes.string,
  columns: PropTypes.number,
  spacing: PropTypes.number,
  alignBtn: PropTypes.oneOf(['start', 'end']),
  reset: PropTypes.bool,
  onReset: PropTypes.func,
  resetLabel: PropTypes.string,
  isResetting: PropTypes.bool,
};

const defaultProps = {
  submitLabel: 'Submit',
  columns: 1,
  spacing: 0,
  alignBtn: 'end',
  reset: false,
  onReset: null,
  resetLabel: 'Reset',
  isResetting: false,
};

const renderField = (props) => {
  let element = null;
  const { field } = props;
  switch (field.type) {
    case 'text':
      element = (<TextField {...props} />);
      break;
    case 'select':
      element = (<SelectField {...props} />);
      break;
    case 'autocomplete':
      element = (<AutocompleteField {...props} />);
      break;
    case 'upload':
      element = (<UploadField {...props} />);
      break;
    default:
      element = (<TextField {...props} />);
      break;
  }
  return element;
};

const Form = ({
  classes,
  fields,
  onSubmit,
  submitLabel,
  columns,
  spacing,
  alignBtn,
  reset,
  onReset,
  resetLabel,
  isResetting,
}) => {
  const initialValues = getFormInitialValues(fields);
  const validations = getFormValidations(fields);
  const maxSize = 12;
  const minSize = maxSize / columns;
  const btnBlock = columns === 1;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validations}
      onSubmit={async (values, props) => { await onSubmit(values, props); }}
      onReset={async (values, props) => { await onReset(values, props); }}
      render={({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleReset,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit} noValidate>
          <Grid spacing={spacing} container>
            {fields.map(field => (
              <Grid xs={maxSize} sm={minSize} md={minSize} item key={field.id}>
                <div className={classes.wrapper}>
                  { renderField({
                    field,
                    values,
                    touched,
                    errors,
                    handleChange,
                    setFieldValue,
                  }) }
                </div>
              </Grid>
            ))}
            <Grid
              alignItems={`flex-${alignBtn}`}
              justify={`flex-${alignBtn}`}
              xs={maxSize}
              container
              item
            >
              {reset && (
                <Button
                  className={classes.reset}
                  type="button"
                  color="primary"
                  disabled={isResetting}
                  onClick={handleReset}
                >
                  {resetLabel}
                </Button>
              )}
              <Button
                className={btnBlock ? classes.wrapper : classes.noMargin}
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                fullWidth={btnBlock}
              >
                {submitLabel}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};


Form.propTypes = propTypes;
Form.defaultProps = defaultProps;
export default withStyles(styles, { withTheme: true })(Form);
