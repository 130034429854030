import axios from 'axios';

import config from '../config';

const createAPI = (apiConf) => {
  const client = axios.create(apiConf);

  const api = {
    queue: {
      get(params, token = '') {
        return client.request({
          method: 'get',
          url: '/queue',
          headers: { Authorization: `Bearer ${token}` },
          params,
        });
      },
      getOne(params, token) {
        return client.request({
          method: 'get',
          url: '/queue/single',
          headers: { Authorization: `Bearer ${token}` },
          params,
        });
      },
      create(data, token) {
        return client.request({
          method: 'post',
          url: '/queue',
          headers: { Authorization: `Bearer ${token}` },
          data,
        });
      },
      process(data, token) {
        return client.request({
          method: 'post',
          url: '/process',
          headers: { Authorization: `Bearer ${token}` },
          data,
        });
      },
    },
    report: {
      getLighthouse(url, token) {
        return client.request({
          method: 'get',
          headers: { Authorization: `Bearer ${token}` },
          url: `/reports/resume/lighthouse?url=${url}`,
        });
      },
      getBrokenLinks(url, token) {
        return client.request({
          method: 'get',
          headers: { Authorization: `Bearer ${token}` },
          url: `/reports/resume/brokenlinks?url=${url}`,
        });
      },
      getSpeedTest(url, token) {
        return client.request({
          method: 'get',
          headers: { Authorization: `Bearer ${token}` },
          url: `/reports/resume/speedtest?url=${url}`,
        });
      },
      getGaTest(url, token) {
        return client.request({
          method: 'get',
          headers: { Authorization: `Bearer ${token}` },
          url: `/reports/resume/analytics?url=${url}`,
        });
      },
      getWpScan(url, token) {
        return client.request({
          method: 'get',
          headers: { Authorization: `Bearer ${token}` },
          url: `/reports/resume/wpscan?url=${url}`,
        });
      },
      getKeywordsRanking(url, token) {
        return client.request({
          method: 'get',
          headers: { Authorization: `Bearer ${token}` },
          url: `/reports/resume/seranking?url=${url}`,
        });
      },
      getSeoReport(url, token) {
        return client.request({
          method: 'get',
          headers: { Authorization: `Bearer ${token}` },
          url: `/reports/complete/seranking?url=${url}`,
        });
      },
      getScreenshotURL(url, host, device) {
        return `${client.defaults.baseURL}/images/?url=${url}&domainName=${host}&mobileType=${device}`;
      },
      getTemplate(url, token) {
        return client.request({
          method: 'get',
          headers: { Authorization: `Bearer ${token}` },
          url: `/report?url=${url}`,
        });
      },
      sendEmail(url, token) {
        return client.request({
          method: 'post',
          headers: { Authorization: `Bearer ${token}` },
          url: '/report/email',
          data: {
            url,
          },
        });
      },
    },
  };
  return api;
};

export default createAPI(config.api);
