import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  withMobileDialog,
  IconButton,
} from '@material-ui/core';

const propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  icon: PropTypes.node.isRequired,
};

const defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};

class ConfirmDialogButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  async handleConfirm() {
    try {
      const { onConfirm } = this.props;
      await onConfirm();
      this.handleClose();
    } catch (e) {
      this.handleClose();
    }
  }

  render() {
    const {
      fullScreen,
      title,
      body,
      cancelText,
      confirmText,
      icon,
    } = this.props;
    const { open } = this.state;
    return (
      <div>
        <IconButton aria-label="Delete" onClick={this.handleOpen}>
          {icon}
        </IconButton>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {body}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="inherit" autoFocus>
              {cancelText}
            </Button>
            <Button onClick={this.handleConfirm} color="primary">
              {confirmText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>);
  }
}

ConfirmDialogButton.propTypes = propTypes;
ConfirmDialogButton.defaultProps = defaultProps;
export default withMobileDialog()(ConfirmDialogButton);
