import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  withMobileDialog,
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';


const propTypes = {
  title: PropTypes.string.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  buttonProps: PropTypes.oneOfType([PropTypes.object]),
  buttonText: PropTypes.string,
  chips: PropTypes.arrayOf(PropTypes.string),
  contentText: PropTypes.string,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const defaultProps = {
  contentText: '',
  open: false,
  buttonProps: {},
  buttonText: 'Open',
  chips: [],
};

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 3,
  },
});

class ChipsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      chips: props.chips,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleChange(values) {
    this.setState({ chips: values });
  }

  async handleSubmit() {
    try {
      const { onSubmit } = this.props;
      const { chips } = this.state;
      await onSubmit(chips);
      this.handleClose();
    } catch (e) {
      this.handleClose();
    }
  }

  render() {
    const {
      fullScreen,
      title,
      disabled,
      buttonProps,
      buttonText,
      chips,
      contentText,
      classes,
    } = this.props;
    const { open } = this.state;
    return (
      <div>
        <Button
          variant="outlined"
          color="default"
          onClick={this.handleOpen}
          {...buttonProps}
        >
          {buttonText}
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {contentText}
            </DialogContentText>
            <div className={classes.container}>
              <ChipInput
                placeholder="Type and press enter to add chips"
                name="chip-input-modal"
                label={title}
                disabled={disabled}
                defaultValue={chips}
                fullWidth
                onChange={this.handleChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="default">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="secondary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>);
  }
}

ChipsModal.propTypes = propTypes;
ChipsModal.defaultProps = defaultProps;
const ChipsModalWithDialog = withMobileDialog()(ChipsModal);
export default withStyles(styles, { withTheme: true })(ChipsModalWithDialog);
