import React from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from '../providers/auth';


const propTypes = {
  children: PropTypes.node.isRequired,
};

const AuthConsumer = ({ children }) => (
  <AuthContext.Consumer>
    {({
      getToken,
      getUser,
      isLoggedIn,
      isLoggedOut,
      authenticate,
      login,
      logout,
    }) => (React.Children.map(children, child => React.cloneElement(child, {
      getToken,
      getUser,
      isLoggedIn,
      isLoggedOut,
      authenticate,
      login,
      logout,
    })))}
  </AuthContext.Consumer>
);

AuthConsumer.propTypes = propTypes;
export default AuthConsumer;
