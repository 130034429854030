import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Typography,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import AccountIcon from '@material-ui/icons/AccountCircle';
import LinkIcon from '@material-ui/icons/Link';
import CalendarIcon from '@material-ui/icons/PermContactCalendar';
import TrendingIcon from '@material-ui/icons/TrendingUp';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';

import withAuth from '../../consumers/withAuth';
import api from '../../services/api';
import { URLParse } from '../../utils/urlParse';
import DataCard from '../../components/DataCard/DataCard';


const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  url: PropTypes.string.isRequired,
};

class Resume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        client: '',
        url: '',
        owner: '',
        creationDate: '',
        type: '',
        status: '',
        email: [],
      },
      loading: true,
      empty: true,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  async fetchData() {
    const { url, auth } = this.props;
    try {
      const resume = await api.queue.getOne({ url }, auth.getToken());
      this.setState({
        data: resume.data,
        loading: false,
        empty: false,
      });
    } catch (error) {
      const { response: { status } } = error;
      this.setState({ loading: false, empty: true });
      if (status === 401) {
        auth.logout();
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { data, loading, empty } = this.state;
    return (
      <DataCard
        title="Audit Result"
        loading={loading}
        empty={empty}
        first
      >
        <Grid
          spacing={32}
          container
          alignItems="center"
          justify="center"
        >
          <Grid sm={4} md={4} item>
            <div className={classes.container}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.client}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={data ? URLParse(data.url).parent_domain : ''}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <AccountIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.owner}
                    secondary="Owner"
                  />
                </ListItem>
              </List>
            </div>
          </Grid>
          <Grid sm={4} md={4} item>
            <div className={classes.container}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={moment(data.creationDate).format('MMM DD, YYYY')}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <TrendingIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={(
                      <Typography variant="button">
                        {data.type}
                      </Typography>
                    )}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DoneIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={(
                      <Typography variant="button">
                        {data.status}
                      </Typography>
                    )}
                  />
                </ListItem>
              </List>
            </div>
          </Grid>
          <Grid sm={4} md={4} item>
            <div className={classes.container}>
              <List>
                {data ? data.email.map(i => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <AccountIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={i}
                      secondary="Recipient"
                    />
                  </ListItem>
                )) : null}
              </List>
            </div>
          </Grid>
        </Grid>
      </DataCard>
    );
  }
}


Resume.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(withAuth(Resume));
