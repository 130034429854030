import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import withAuth from '../../consumers/withAuth';
import logo from '../../assets/images/mwe-white.png';


const styles = theme => ({
  wrapper: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  container: {
    minHeight: '100vh',
  },
  paper: {
    width: '280px',
    padding: theme.spacing.unit * 3,
    '@media (max-width: 320px)': {
      width: '240px',
    },
    '@media (max-width: 240px)': {
      width: '255px',
    },
  },
  logoContainer: {
    marginLeft: '-24px',
    marginRight: '-24px',
    marginBottom: '30px',
    backgroundColor: theme.palette.primary.main,
    marginTop: '-24px',
    borderRadius: '4px 4px 0px 0px',
    padding: '40px',
  },
  logo: {
    width: '250px',
  },
});

const propTypes = {
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const Logout = ({ auth, classes }) => {
  const handleLogin = () => auth.login();
  return (
    <Grid justify="center" alignItems="center" className={classes.container} container>
      <Paper className={classes.paper}>
        <div className={classes.logoContainer}>
          <Typography align="center">
            <img src={logo} alt="Medical Web Experts Logo" className={classes.logo} />
          </Typography>
        </div>
        <Typography variant="subtitle1" align="center" gutterBottom>
            You have been logged out. To login back to the application
            click the button below.
        </Typography>
        <Button
          className={classes.wrapper}
          onClick={handleLogin}
          type="button"
          variant="contained"
          color="secondary"
          fullWidth
        >
          Login
        </Button>
      </Paper>
    </Grid>
  );
};

Logout.propTypes = propTypes;
const LogoutInjected = withAuth(Logout);
export default withStyles(styles, { withTheme: true })(LogoutInjected);
