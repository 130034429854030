import React from 'react';
import {
  Dashboard,
  Schedule,
  TrendingUp,
} from '@material-ui/icons';
import {
  PATHS,
  DASHBOARD,
  SCHEDULE,
  AUDITS,
} from '../constants/paths';

const routes = PATHS.map((item) => {
  let icon = null;
  switch (item.key) {
    case DASHBOARD:
      icon = <Dashboard />;
      break;
    case SCHEDULE:
      icon = <Schedule />;
      break;
    case AUDITS:
      icon = <TrendingUp />;
      break;
    default:
      icon = null;
      break;
  }
  return Object.assign({ icon, external: false }, item);
});

export default routes;
