import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { green, red } from '@material-ui/core/colors';

import withAuth from '../../consumers/withAuth';
import api from '../../services/api';
import DataCard from '../../components/DataCard/DataCard';


const styles = () => ({
  successText: {
    color: green[600],
  },
  errorText: {
    color: red[600],
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  url: PropTypes.string.isRequired,
};


class GaTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        hasGA: null,
        hasGT: null,
      },
      loading: true,
      empty: true,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  async fetchData() {
    const { auth, url } = this.props;
    try {
      const gaTest = await api.report.getGaTest(url, auth.getToken());
      // eslint-disable-next-line
      const { data: { data: { has_google_analytics, has_google_tags } } } = gaTest;
      this.setState({
        data: {
          hasGA: has_google_analytics,
          hasGT: has_google_tags,
        },
        loading: false,
        empty: false,
      });
    } catch (error) {
      const { response: { status } } = error;
      this.setState({ loading: false, empty: true });
      if (status === 401) {
        auth.logout();
      }
    }
  }

  render() {
    const { data, loading, empty } = this.state;
    const { hasGA, hasGT } = data;
    const { classes } = this.props;
    return (
      <DataCard
        title="Google Analytics"
        loading={loading}
        empty={empty}
      >
        <Grid
          spacing={32}
          container
          alignItems="center"
          justify="center"
        >
          <Grid sm={6} md={6} item>
            {hasGA === true
              ? <CheckIcon fontSize="large" className={classes.successText} />
              : <CloseIcon fontSize="large" className={classes.errorText} />}
            <Typography variant="overline" gutterBottom>
             Has Google Analytics Code
            </Typography>
          </Grid>
          <Grid sm={6} md={6} item>
            {hasGT === true
              ? <CheckIcon fontSize="large" className={classes.successText} />
              : <CloseIcon fontSize="large" className={classes.errorText} />}
            <Typography variant="overline" gutterBottom>
              Has Google Tag Manager
            </Typography>
          </Grid>
        </Grid>
      </DataCard>
    );
  }
}


GaTest.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(withAuth(GaTest));
