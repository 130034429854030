import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Drawer as MuiDrawer,
  List,
  Hidden,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import routes from '../../navigation/routes';
import { PATH_NAME, DASHBOARD } from '../../constants/paths';
import logo from '../../assets/images/mwe.png';
import './Drawer.css';

const styles = theme => ({
  listItemRoot: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 22,
    paddingRight: 22,
  },
  activeText: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  toolbar: theme.mixins.toolbar,
  listRoot: {
    paddingTop: 0,
  },
  drawerPaper: {
    width: theme.drawer.width,
    backgroundColor: grey[100],
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
    },
  },
  listIconRoot: {
    color: theme.palette.secondary.main,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  mobileOpen: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
};

const defaultProps = {
  mobileOpen: false,
};

const isActive = (path, current) => {
  if (path !== PATH_NAME[DASHBOARD] && current !== PATH_NAME[DASHBOARD]) {
    if (path === current || current.indexOf(path) !== -1) {
      return true;
    }
  }
  if (path === PATH_NAME[DASHBOARD] && current === PATH_NAME[DASHBOARD]) {
    return true;
  }
  return false;
};

const getItems = (currentPath, classes, toggleHandler) => (
  <List className={classes.listRoot}>
    <ListItem className="listLogo" disableGutters>
      <img src={logo} className="logo" alt="logo" />
    </ListItem>
    <Divider />
    { routes.map((route) => {
      const active = isActive(route.path, currentPath);
      const isExternalLink = (route.external);
      const item = (
        <ListItem button disableGutters className={classes.listItemRoot}>
          <ListItemIcon className={(active) ? classes.listIconRoot : ''}>
            {route.icon}
          </ListItemIcon>
          <ListItemText
            primary={route.text}
            primaryTypographyProps={{ className: (active) ? classes.activeText : '' }}
          />
        </ListItem>
      );
      let link = null;
      if (isExternalLink) {
        link = (
          <a href={route.path} target="_blank" rel="noopener noreferrer">
            {item}
          </a>);
      } else {
        link = (
          <Link to={route.path} onClick={toggleHandler}>
            {item}
          </Link>);
      }
      return (
        <li className={(active) ? 'active' : ''} key={route.key}>
          {link}
          <Divider />
        </li>);
    })}
  </List>
);

const Drawer = ({
  mobileOpen,
  toggleHandler,
  classes,
  theme,
  location,
}) => (
  <div>
    <Divider />
    <Hidden lgUp>
      <MuiDrawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={toggleHandler}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{ keepMounted: true }}
      >
        {getItems(location.pathname, classes, toggleHandler)}
      </MuiDrawer>
    </Hidden>
    <Hidden mdDown implementation="css">
      <MuiDrawer
        variant="permanent"
        open
        classes={{ paper: classes.drawerPaper }}
      >
        {getItems(location.pathname, classes)}
      </MuiDrawer>
    </Hidden>
  </div>
);

Drawer.propTypes = propTypes;
Drawer.defaultProps = defaultProps;

const DrawerStyled = withStyles(styles, { withTheme: true })(Drawer);
export default withRouter(DrawerStyled);
