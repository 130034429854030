import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

const propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    customProps: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleChange: PropTypes.func.isRequired,
};

const onChange = (event, handleChange, field) => {
  handleChange(event);
  if (field.customProps && field.customProps.onChange) {
    field.customProps.onChange(event.target.value);
  }
};

const SelectField = ({
  field,
  values,
  touched,
  errors,
  handleChange,
}) => (
  <FormControl error={Boolean(touched[field.name] && errors[field.name])} fullWidth>
    <InputLabel htmlFor="type" required={Boolean(field.required)}>
      {field.label}
    </InputLabel>
    <Select
      value={values[field.name]}
      {...field.customProps}
      onChange={(event) => { onChange(event, handleChange, field); }}
    >
      <MenuItem value="">
        <em>
          {field.placeholder}
        </em>
      </MenuItem>
      {field.customProps.data.map(item => (
        <MenuItem value={item.value} key={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
    {touched[field.name] && errors[field.name] && (
      <FormHelperText error>
        {errors[field.name]}
      </FormHelperText>
    )}
  </FormControl>
);

SelectField.propTypes = propTypes;
export default SelectField;
