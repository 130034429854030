import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import isMobile from '../../utils/isMobile';


const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  collapsedText: PropTypes.string,
};

const defaultProps = {
  title: '',
  collapsedText: '',
};

const styles = theme => ({
  checkbox: {
    alignItems: 'flex-end',
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: 20,
  },
  root: {
    display: 'block',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
});

class ResponsiveExpansionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: !isMobile(),
    };
  }

  componentWillMount() {
    this.setState({ expanded: !isMobile() });
  }

  render() {
    const {
      classes,
      children,
      collapsedText,
      title,
    } = this.props;
    const { expanded } = this.state;
    let subHeading = null;
    if (isMobile() && !expanded) {
      subHeading = (
        <Typography className={classes.secondaryHeading}>
          {collapsedText}
        </Typography>);
    }
    return (
      <ExpansionPanel defaultExpanded={!isMobile()} onChange={this.handleChange}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid spacing={32} container>
            <Grid xs={6} md={6} item>
              <Typography variant="h6">
                {title}
              </Typography>
            </Grid>
            <Grid xs={6} md={6} container item alignItems="center" justify="flex-end">
              {subHeading}
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.root }}>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

ResponsiveExpansionPanel.propTypes = propTypes;
ResponsiveExpansionPanel.defaultProps = defaultProps;
export default withStyles(styles)(ResponsiveExpansionPanel);
