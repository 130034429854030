import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, CircularProgress, Typography } from '@material-ui/core';

import withAuth from '../../consumers/withAuth';


const styles = {
  container: {
    minHeight: '100vh',
  },
  icon: {
    padding: 20,
  },
};

const propTypes = {
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

class Callback extends Component {
  async componentWillMount() {
    const { auth } = this.props;
    await auth.authenticate();
  }

  render() {
    const { auth, history } = this.props;
    if (auth.isLoggedIn()) {
      history.push('/');
    }
    return (
      <Grid justify="center" alignItems="center" direction="column" style={styles.container} container>
        <CircularProgress color="secondary" style={styles.icon} />
        <Typography variant="h5" align="center" gutterBottom>
          Loading Profile
        </Typography>
      </Grid>
    );
  }
}

Callback.propTypes = propTypes;
export default withRouter(withAuth(Callback));
