import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Stepper from '../containers/Schedule/Stepper';

const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};


const SchedulePage = ({ classes }) => (
  <div className={classes.list}>
    <Stepper />
  </div>
);


SchedulePage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(SchedulePage);
