import yup from './validations';

export const arrayToObject = (array, keyField) => (
  array.reduce((obj, item) => {
    /* eslint-disable-next-line no-param-reassign */
    obj[item[keyField]] = item;
    return obj;
  }, {})
);
export const getFormInitialValues = array => (
  array.reduce((obj, item) => {
    /* eslint-disable-next-line no-param-reassign */
    obj[item.name] = item.defaultVal;
    return obj;
  }, {})
);

export const getFormValidations = array => (
  yup.object().shape(
    array.reduce((obj, item) => {
      let validator = null;
      let schema = null;
      switch (item.validateAs) {
        case 'email':
          validator = yup.string().email();
          break;
        case 'password':
          schema = item.passwordSchema;
          validator = yup
            .string()
            .min(schema.min)
            .matches(schema.matches, schema.errorMsg);
          break;
        case 'confirm':
          schema = item.confirmSchema;
          validator = yup
            .string()
            .oneOf([yup.ref(schema.compareAgainst)], schema.errorMsg);
          break;
        case 'url':
          validator = yup.string().url();
          break;
        case 'string':
          validator = yup.string();
          break;
        case 'number':
          validator = yup.number();
          break;
        case 'mixed':
          validator = yup.mixed();
          break;
        case 'array':
          validator = yup.array().min(1);
          break;
        default:
          validator = yup.string();
          break;
      }
      if (item.required === true) {
        validator = validator.required();
      }
      /* eslint-disable-next-line no-param-reassign */
      obj[item.name] = validator;
      return obj;
    }, {}),
  )
);
