import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText } from '@material-ui/core';
import InputBase64 from '../InputBase64/InputBase64';

const styles = () => ({
  uploadContainer: {
    backgroundColor: '#f9f9f9',
  },
  uploadLabelValidation: {
    position: 'absolute',
    bottom: -16,
    left: 3,
  },
});

const propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    customProps: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const UploadField = ({
  field,
  touched,
  errors,
  setFieldValue,
  classes,
}) => (
  <FormControl
    error={Boolean(touched[field.name] && errors[field.name])}
    className={classes.uploadContainer}
    fullWidth
  >
    <InputBase64
      label={field.label}
      id="flat-button-file"
      onDone={(value) => { setFieldValue(field.name, value); }}
    />
    {touched[field.name] && errors[field.name] && (
      <FormHelperText
        classes={{
          root: classes.uploadLabelValidation,
        }}
        error
      >
        {errors[field.name]}
      </FormHelperText>
    )}
  </FormControl>
);

UploadField.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(UploadField);
