import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import green from '@material-ui/core/colors/green';


const styles = theme => ({
  container: {
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    margin: theme.spacing.unit,
    color: green[600],
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};


const DoneStep = ({ classes }) => (
  <Grid xs={6} sm={6} md={6} item>
    <div className={classes.container}>
      <DoneAllIcon className={classes.icon} fontSize="large" />
      <Typography variant="h6" color="textSecondary">
        All set! We will notify you when is ready.
      </Typography>
    </div>
  </Grid>
);


DoneStep.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(DoneStep);
