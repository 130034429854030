import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';

import withAuth from '../../consumers/withAuth';
import api from '../../services/api';
import DataCard from '../../components/DataCard/DataCard';


const styles = () => ({
  successText: {
    color: green[600],
  },
  errorText: {
    color: red[600],
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  url: PropTypes.string.isRequired,
};


class SpeedTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        meaningfulPaint: 0,
        firstInteraction: 0,
        fullyLoad: 0,
      },
      loading: true,
      empty: true,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  async fetchData() {
    const { auth, url } = this.props;
    try {
      const speedTest = await api.report.getSpeedTest(url, auth.getToken());
      // eslint-disable-next-line
      const { data: { data: { first_meaningful_paint, time_first_interaction, time_fully_load } } } = speedTest;
      this.setState({
        data: {
          meaningfulPaint: first_meaningful_paint,
          firstInteraction: time_first_interaction,
          fullyLoad: time_fully_load,
        },
        loading: false,
        empty: false,
      });
    } catch (error) {
      const { response: { status } } = error;
      this.setState({ loading: false, empty: true });
      if (status === 401) {
        auth.logout();
      }
    }
  }

  render() {
    const { data, loading, empty } = this.state;
    const { meaningfulPaint, firstInteraction, fullyLoad } = data;
    const { classes } = this.props;
    return (
      <DataCard
        title="Speed Test"
        loading={loading}
        empty={empty}
      >
        <Grid
          spacing={32}
          container
          alignItems="center"
          justify="center"
        >
          <Grid sm={4} md={4} item>
            <Typography variant="h4" className={meaningfulPaint > 1001 ? classes.errorText : classes.successText} gutterBottom>
              {`${(meaningfulPaint / 1000).toFixed(2)}s`}
            </Typography>
            <Typography variant="overline" gutterBottom>
             First Meaningful Paint
            </Typography>
          </Grid>
          <Grid sm={4} md={4} item>
            <Typography variant="h4" className={firstInteraction > 3001 ? classes.errorText : classes.successText} gutterBottom>
              {`${(firstInteraction / 1000).toFixed(2)}s`}
            </Typography>
            <Typography variant="overline" gutterBottom>
              Time to first interaction
            </Typography>
          </Grid>
          <Grid sm={4} md={4} item>
            <Typography variant="h4" className={fullyLoad > 3501 ? classes.errorText : classes.successText} gutterBottom>
              {`${(fullyLoad / 1000).toFixed(2)}s`}
            </Typography>
            <Typography variant="overline" gutterBottom>
              Time to fully load
            </Typography>
          </Grid>
        </Grid>
      </DataCard>
    );
  }
}


SpeedTest.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(withAuth(SpeedTest));
