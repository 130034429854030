import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Hidden,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import TableCell from './TableCell';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 4,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progressColorPrimary: {
    backgroundColor: '#ff9930',
  },
  barColorPrimary: {
    backgroundColor: '#ffcc97',
  },
  container: {
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  header: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRows: PropTypes.func.isRequired,
  paginate: PropTypes.bool,
  renderPagination: PropTypes.func,
  emptyRows: PropTypes.number,
  flat: PropTypes.bool,
  loading: PropTypes.bool,
  empty: PropTypes.bool,
};

const defaultProps = {
  renderPagination: () => {},
  paginate: false,
  emptyRows: 0,
  flat: false,
  loading: false,
  empty: true,
};

const renderHeader = colums => (
  colums.map((column) => {
    const cell = (
      <TableCell
        key={column.id}
        padding="default"
      >
        {column.label}
      </TableCell>
    );

    if (column.hidden) {
      return (
        <Hidden
          key={column.id}
          {...column.hidden}
        >
          { cell }
        </Hidden>
      );
    }

    return cell;
  })
);

const renderLoading = classes => (
  <LinearProgress
    classes={{
      colorPrimary: classes.colorPrimary,
      barColorPrimary: classes.progressColorPrimary,
    }}
  />
);

const renderEmpty = classes => (
  <div className={classes.container}>
    <Typography variant="overline" color="textSecondary">
      No data available
    </Typography>
  </div>
);

const DataTable = ({
  classes,
  header,
  renderRows,
  paginate,
  renderPagination,
  emptyRows,
  flat,
  loading,
  empty,
}) => {
  const table = (
    <div>
      <div className={classes.tableWrapper}>
        {loading && renderLoading(classes)}
        <Table className={classes.table} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              { renderHeader(header) }
            </TableRow>
          </TableHead>
          <TableBody>
            { renderRows() }
            { emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      { paginate && renderPagination() }
    </div>
  );
  if (empty) {
    return (
      <Paper className={classes.root}>
        {loading && renderLoading(classes)}
        {renderEmpty(classes)}
      </Paper>
    );
  }
  if (!flat) {
    return (
      <Paper className={classes.root}>
        { table }
      </Paper>
    );
  }

  return table;
};

DataTable.propTypes = propTypes;
DataTable.defaultProps = defaultProps;
export default withStyles(styles)(DataTable);
