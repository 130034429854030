import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import Circle from 'react-circle';

import withAuth from '../../consumers/withAuth';
import api from '../../services/api';
import DataCard from '../../components/DataCard/DataCard';


const propTypes = {
  url: PropTypes.string.isRequired,
};


class Lighthouse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        accessibility: 0,
        bestpractices: 0,
        performance: 0,
        progressive: 0,
        seo: 0,
      },
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  async fetchData() {
    const { url, auth } = this.props;
    try {
      const lighthouse = await api.report.getLighthouse(url, auth.getToken());
      const {
        data: {
          data: {
            score: {
              accessibility,
              bestpractices,
              performance,
              progressive,
              seo,
            },
          },
        },
      } = lighthouse;
      this.setState({
        data: {
          accessibility,
          bestpractices,
          performance,
          progressive,
          seo,
        },
        loading: false,
        empty: false,
      });
    } catch (error) {
      const { response: { status } } = error;
      this.setState({ loading: false, empty: true });
      if (status === 401) {
        auth.logout();
      }
    }
  }

  render() {
    const { data, loading, empty } = this.state;
    return (
      <DataCard
        title="Lighthouse"
        loading={loading}
        empty={empty}
      >
        <Grid
          spacing={32}
          container
          alignItems="center"
          justify="center"
        >
          <Grid sm={2} md={2} item>
            <Circle progress={Math.round(data.accessibility * 100)} progressColor="#ff9930" />
            <Typography variant="overline" gutterBottom>
              Accesibility
            </Typography>
          </Grid>
          <Grid sm={2} md={2} item>
            <Circle progress={Math.round(data.bestpractices * 100)} progressColor="#ff9930" />
            <Typography variant="overline" gutterBottom>
              Best Practices
            </Typography>
          </Grid>
          <Grid sm={2} md={2} item>
            <Circle progress={Math.round(data.performance * 100)} progressColor="#ff9930" />
            <Typography variant="overline" gutterBottom>
              Performance
            </Typography>
          </Grid>
          <Grid sm={2} md={2} item>
            <Circle progress={Math.round(data.progressive * 100)} progressColor="#ff9930" />
            <Typography variant="overline" gutterBottom>
              PWA
            </Typography>
          </Grid>
          <Grid sm={2} md={2} item>
            <Circle progress={Math.round(data.seo * 100)} progressColor="#ff9930" />
            <Typography variant="overline" gutterBottom>
              SEO
            </Typography>
          </Grid>
        </Grid>
      </DataCard>
    );
  }
}


Lighthouse.propTypes = propTypes;
export default withAuth(Lighthouse);
