let API_URL = 'http://localhost:3000';
let WWW_HOST = 'http://localhost:3001';
// let AUTH0_AUDIENCE = 'https://mweformsapi.mwe.cloud/';

if (process.env.REACT_APP_STAGE === 'test') {
  API_URL = 'https://mweformsapi.mwe.cloud';
  WWW_HOST = 'https://forms.mwe.cloud';
  // AUTH0_AUDIENCE = 'https://mweformsapi.mwe.cloud/';
} else if (process.env.REACT_APP_STAGE === 'prod') {
  API_URL = 'https://formsapi.medicalwebexperts.com';
  WWW_HOST = 'https://forms.medicalwebexperts.com';
  // AUTH0_AUDIENCE = 'https://formsapi.medicalwebexperts.com/';
}

export default {
  api: {
    baseURL: API_URL,
    timeout: 20000,
  },
  auth0: {
    domain: 'mweaudit.auth0.com',
    // audience: AUTH0_AUDIENCE,
    clientID: 'CAQGaP0GSy1npMaJ74xCdeB8ZpMXB5oQ',
    redirectUri: `${WWW_HOST}/callback`,
    responseType: 'token id_token',
    scope: 'openid profile',
  },
};
