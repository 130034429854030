export const DASHBOARD = 'dashboard';
export const SCHEDULE = 'schedule';
export const AUDITS = 'audits';
export const AUDITS_RESULT = 'audits_result';
export const LOGOUT = 'logout';

export const KEY_LABEL = {
  [DASHBOARD]: 'Dashboard',
  [SCHEDULE]: 'Schedule',
  [AUDITS]: 'Audits',
  [AUDITS_RESULT]: 'Audit Result',
  [LOGOUT]: 'Logout',
};

export const PATH_NAME = {
  [DASHBOARD]: '/',
  [SCHEDULE]: '/schedule',
  [AUDITS]: '/audits',
  [AUDITS_RESULT]: '/audits/result',
  [LOGOUT]: '/logout',
};

export const PATH_LABEL = {
  [PATH_NAME[DASHBOARD]]: 'Dashboard',
  [PATH_NAME[SCHEDULE]]: 'Schedule',
  [PATH_NAME[AUDITS]]: 'Audits',
  [PATH_NAME[AUDITS_RESULT]]: 'Audit Result',
  [PATH_NAME[LOGOUT]]: 'Logout',
};

export const PATHS = [
  {
    key: DASHBOARD,
    path: PATH_NAME[DASHBOARD],
    text: KEY_LABEL[DASHBOARD],
  },
  {
    key: SCHEDULE,
    path: PATH_NAME[SCHEDULE],
    text: KEY_LABEL[SCHEDULE],
  },
  {
    key: AUDITS,
    path: PATH_NAME[AUDITS],
    text: KEY_LABEL[AUDITS],
  },
];
