export const IPHONE8 = 'iPhone8';
export const IPHONE8PLUS = 'iPhone8Plus';
export const IPHONEX = 'iPhoneX';
export const IPAD = 'iPad';
export const PIXEL2 = 'Pixel2';

export const DEVICES = [
  IPHONE8,
  IPHONE8PLUS,
  IPHONEX,
  IPAD,
  PIXEL2,
];

export const DEVICES_LIST = {
  [IPHONE8]: 'iPhone 8',
  [IPHONE8PLUS]: 'iPhone 8 Plus',
  [IPHONEX]: 'iPhone X',
  [IPAD]: 'iPad',
  [PIXEL2]: 'Google Pixel 2',
};

export const DEVICES_WIDTH = {
  [IPHONE8]: 200,
  [IPHONE8PLUS]: 200,
  [IPHONEX]: 200,
  [IPAD]: 400,
  [PIXEL2]: 200,
};

export const DEVICES_RAD = {
  [IPHONE8]: 12,
  [IPHONE8PLUS]: 17,
  [IPHONEX]: 14,
  [IPAD]: 14,
  [PIXEL2]: 14,
};
