import React from 'react';
import PropTypes from 'prop-types';
import {
  TablePagination,
  TableRow,
  Hidden,
  Typography,
  IconButton,
} from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import moment from 'moment';

import { TYPE_LIST, TYPE_LABEL, DONE } from '../../constants/queue';
import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import SearchForm from '../../components/SearchForm/SearchForm';
import withAuth from '../../consumers/withAuth';
import api from '../../services/api';

const propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  paginate: PropTypes.bool,
  showEmptyRows: PropTypes.bool,
  search: PropTypes.bool,
  defaultLimit: PropTypes.number,
};

const defaultProps = {
  paginate: false,
  search: false,
  showEmptyRows: false,
  defaultLimit: 10,
};

class AuditTable extends React.Component {
  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;
    this.state = {
      data: [],
      limit: defaultLimit,
      filters: { status: DONE },
      total: 0,
      page: 0,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.handleViewClick = this.handleViewClick.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  static getTableHeader() {
    return [
      { id: 1, label: 'Client', hidden: { xsDown: true } },
      { id: 2, label: 'Date Created', hidden: { xsDown: true } },
      { id: 3, label: 'Email' },
      { id: 4, label: 'URL', hidden: { mdDown: true } },
      { id: 6, label: 'Type', hidden: { xsDown: true } },
      { id: 7, label: '' },
    ];
  }

  async fetchData(searchFilters = null, reset = false) {
    const { auth } = this.props;
    this.setState({ loading: true });
    const { page, limit, filters } = this.state;
    const newFilters = searchFilters || filters;
    const offset = limit * page;
    const params = Object.assign({ offset, limit, status: DONE }, reset ? {} : newFilters);
    try {
      const response = await api.queue.get(params, auth.getToken());
      const { data } = response;
      this.setState({
        data: data.response,
        filters: reset ? { status: DONE } : newFilters,
        total: data.count,
        loading: false,
      });
    } catch (error) {
      this.setState({
        data: [],
        filters: newFilters,
        loading: false,
      });
    }
  }

  handleChangePage(event, page) {
    this.setState({ page }, () => this.fetchData());
  }

  handleChangeRowsPerPage(event) {
    this.setState({ limit: event.target.value }, () => this.fetchData());
  }

  handleViewClick(row) {
    const { history } = this.props;
    history.push(`/audits/result?url=${row.url}`);
  }

  renderRows() {
    const { data } = this.state;
    return (
      data.map(row => (
        <TableRow
          hover
          tabIndex={-1}
          key={row.url}
        >
          <TableCell component="th" scope="row" padding="default">
            {row.client}
          </TableCell>
          <Hidden xsDown>
            <TableCell>
              { moment(row.creationDate).format('MMM DD, YYYY') }
            </TableCell>
          </Hidden>
          <TableCell>
            {(row.email.length > 1) ? `${row.email[0]} ... +${row.email.length - 1}` : row.email[0]}
          </TableCell>
          <Hidden mdDown>
            <TableCell>
              {row.url}
            </TableCell>
          </Hidden>
          <Hidden xsDown>
            <TableCell>
              <Typography variant="button" color="textSecondary" gutterBottom>
                {TYPE_LABEL[row.type]}
              </Typography>
            </TableCell>
          </Hidden>
          <TableCell padding="none" isActions>
            <IconButton aria-label="View" onClick={() => this.handleViewClick(row)}>
              <EyeIcon color="primary" />
            </IconButton>
          </TableCell>
        </TableRow>
      ))
    );
  }

  renderPagination() {
    const { total, limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  }

  renderSearch() {
    const fields = [
      {
        id: 'url',
        name: 'url',
        label: 'Website URL',
        type: 'text',
        validateAs: 'string',
        defaultVal: '',
        required: false,
      },
      {
        id: 'type',
        name: 'type',
        label: 'Type',
        type: 'select',
        placeholder: 'Select one',
        validateAs: 'bool',
        defaultVal: '',
        required: false,
        customProps: {
          inputProps: {
            name: 'type',
            id: 'type',
          },
          data: TYPE_LIST,
        },
      },
      {
        id: 'dateGreater',
        name: 'dateGreater',
        label: 'Date From',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        id: 'dateLess',
        name: 'dateLess',
        label: 'Date To',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
    ];

    return (
      <SearchForm
        onSubmit={this.fetchData}
        onReset={() => this.fetchData(null, true)}
        fields={fields}
      />);
  }

  render() {
    const {
      data,
      loading,
      limit,
      offset,
    } = this.state;
    const { paginate, search, showEmptyRows } = this.props;
    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = limit - Math.min(limit, data.length - offset * limit);
    }
    const searchComponent = (search) ? this.renderSearch() : null;
    return (
      <div>
        {searchComponent}
        <DataTable
          header={AuditTable.getTableHeader()}
          renderRows={this.renderRows}
          emptyRows={emptyRows}
          paginate={paginate}
          renderPagination={this.renderPagination}
          loading={loading}
          empty={data.length === 0}
        />
      </div>
    );
  }
}


AuditTable.propTypes = propTypes;
AuditTable.defaultProps = defaultProps;
export default withAuth(AuditTable);
