import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import withAuth from '../../consumers/withAuth';

const propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]),
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const defaultProps = {
  location: null,
};

const PrivateRoute = ({
  component: Component,
  auth,
  ...rest
}) => {
  const wasManualLogout = auth.isLoggedOut();

  if (!wasManualLogout && !auth.isLoggedIn()) {
    auth.login();
  } else if (wasManualLogout && !auth.isLoggedIn()) {
    return (<Redirect to="/logout" />);
  }

  return (
    <Route
      {...rest}
      render={props => (auth.isLoggedIn() ? <Component {...props} /> : null)}
    />
  );
};

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;
export default withRouter(withAuth(PrivateRoute));
