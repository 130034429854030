import React from 'react';
import PropTypes from 'prop-types';
import auth0 from 'auth0-js';

import config from '../config';
import Storage from '../services/storage';


const propTypes = {
  children: PropTypes.node.isRequired,
};

const DEFAULT_STATE = {
  token: Storage.getItem('token') || null,
  user: Storage.getObject('prof') || null,
  loggedOut: Storage.getItem('mlo') || false,
};

export const AuthContext = React.createContext(DEFAULT_STATE);

class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
    this.auth0 = new auth0.WebAuth(config.auth0);
    this.getToken = this.getToken.bind(this);
    this.getUser = this.getUser.bind(this);
    this.isLoggedOut = this.isLoggedOut.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  getToken() {
    const { token } = this.state;
    return token;
  }

  getUser() {
    const { user } = this.state;
    return user;
  }

  isLoggedIn() {
    const { token, user } = this.state;
    return token !== null && user !== null;
  }

  isLoggedOut() {
    const { loggedOut } = this.state;
    return loggedOut;
  }

  handleLogin() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    try {
      this.auth0.parseHash((err, authResult) => {
        if (err) return Promise.reject(err);
        if (!authResult || !authResult.accessToken) {
          return Promise.reject(err);
        }
        if (authResult.accessToken && authResult.idTokenPayload) {
          Storage.setItem('token', authResult.accessToken);
          Storage.setObject('prof', authResult.idTokenPayload);
          this.setState({
            token: authResult.accessToken,
            user: authResult.idTokenPayload,
          });
          return Promise.resolve(authResult.idTokenPayload);
        }
        return Promise.reject();
      });
      return Promise.reject();
    } catch (e) {
      e.error = true;
      return Promise.reject(e);
    }
  }

  handleLogout() {
    Storage.clear();
    Storage.setItem('mlo', true);
    this.setState({
      token: null,
      user: null,
      loggedOut: true,
    });
  }

  render() {
    const { children } = this.props;
    return (
      <AuthContext.Provider value={{
        getToken: this.getToken,
        getUser: this.getUser,
        isLoggedIn: this.isLoggedIn,
        isLoggedOut: this.isLoggedOut,
        authenticate: this.handleAuthentication,
        login: this.handleLogin,
        logout: this.handleLogout,
      }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = propTypes;
export default AuthProvider;
