import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TableRow,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import ConfirmDialogButton from '../../components/ConfirmDialogButton/ConfirmDialogButton';
import ChipsModal from '../ChipsModal/ChipsModal';
import { BASIC, PRO } from '../../constants/queue';


const styles = () => ({
  container: {
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorSwitchBase: {
    color: '#ffcc97',
    '&$colorChecked': {
      color: '#ff9930',
      '& + $colorBar': {
        backgroundColor: '#ff9930',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  input: {
    display: 'none',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTypeSelected: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onKeywordsChange: PropTypes.func.isRequired,
};

const showEmptyMessage = classes => (
  <Grid xs={6} sm={6} md={6} item>
    <div className={classes.container}>
      <Typography variant="button" color="textSecondary">
        Empty list. Go back to the previous step to Add Clients.
      </Typography>
    </div>
  </Grid>
);

const getRows = (classes, data, onTypeSelected, onDelete, onKeywordsChange) => (
  data.map((row, i) => (
    <TableRow
      hover
      tabIndex={-1}
      key={i} // eslint-disable-line
    >
      <TableCell component="th" scope="row" padding="default">
        {row.client}
      </TableCell>
      <TableCell>
        {(row.email.length > 1) ? `${row.email[0]} ... +${row.email.length - 1}` : row.email[0]}
      </TableCell>
      <TableCell>
        {row.url}
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={(
            <Switch
              checked={row.type === PRO}
              onChange={() => onTypeSelected(row)}
              value={row.type === PRO ? PRO : BASIC}
              classes={{
                switchBase: classes.colorSwitchBase,
                checked: classes.colorChecked,
                bar: classes.colorBar,
              }}
            />
          )}
          label={(
            <Typography variant="button" color="textSecondary">
              {row.type}
            </Typography>
          )}
        />
      </TableCell>
      <TableCell>
        {row.type === BASIC && (
          <Typography variant="button" color="textSecondary">
              -
          </Typography>)}
        {(row.type === PRO) && (
          <ChipsModal
            title="Add Keywords"
            chips={row.keywords}
            onSubmit={(keywords) => {
              onKeywordsChange(row.url, keywords);
            }}
            buttonProps={{
              variant: 'text',
              color: 'secondary',
            }}
            buttonText="Edit"
            contentText="Use the field below to add the keywords to be analyzed.
            Type and press enter to add a keyword."
          />)}
      </TableCell>
      <TableCell padding="none" isActions>
        <ConfirmDialogButton
          icon={<DeleteIcon />}
          onConfirm={() => onDelete(row)}
          title="Are you sure you want to remove this site?"
          body={`Remove ${row.url} from the list.`}
        />
      </TableCell>
    </TableRow>
  ))
);

const SelectTypeStep = ({
  classes,
  data,
  onTypeSelected,
  onDelete,
  onKeywordsChange,
}) => {
  if (data.length === 0) {
    return showEmptyMessage(classes);
  }
  return (
    <Grid xs={12} sm={12} md={12} item>
      <DataTable
        header={[
          { id: 1, label: 'Client' },
          { id: 2, label: 'Email' },
          { id: 3, label: 'URL' },
          { id: 4, label: 'Type' },
          { id: 5, label: 'Keywords' },
          { id: 6, label: '' },
        ]}
        renderRows={() => getRows(classes, data, onTypeSelected, onDelete, onKeywordsChange)}
        paginate={false}
        flat
        empty={data.length === 0}
      />
    </Grid>
  );
};


SelectTypeStep.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(SelectTypeStep);
