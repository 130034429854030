import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  '@media (max-width: 1200px) and (min-width: 950px)': {
    container: {
      flexWrap: 'wrap',
    },
    wrapperButton: {
      width: '100%',
    },
    text: {
      paddingLeft: 10,
      paddingTop: 5,
    },
  },
  '@media (max-width: 600px)': {
    container: {
      flexWrap: 'wrap',
    },
    wrapperButton: {
      width: '100%',
    },
    text: {
      paddingLeft: 10,
      paddingTop: 5,
    },
  },
});

const propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onDone: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

class InputBase64 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { files } = e.target;

    if (files.length > 0) {
      const { onDone } = this.props;

      const file = files[0];
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileInfo = {
          filename: file.name,
          type: file.type,
          size: Math.round(file.size / 1000),
          base64: reader.result,
        };

        this.setState({
          file: { filename: fileInfo.filename },
        });
        onDone(fileInfo);
      };
    }
  }

  render() {
    const { label, classes, id } = this.props;
    const { file } = this.state;

    return (
      <Grid alignItems="center" className={classes.container} container>
        <div className={classes.wrapperButton}>
          <label htmlFor={id}>
            <input
              className={classes.input}
              id={id}
              type="file"
              onChange={this.handleChange}
            />
            <Button
              variant="outlined"
              color="primary"
              component="span"
              className={classes.button}
            >
              {label}
            </Button>
          </label>
        </div>
        {file && (
          <div>
            <Typography className={classes.text}>
              <strong>
                Filename:
              </strong>
              {' '}
              <em>
                {file.filename}
              </em>
            </Typography>
          </div>
        )}
      </Grid>
    );
  }
}

InputBase64.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(InputBase64);
