import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import withAuth from '../consumers/withAuth';
import Logout from '../containers/Logout/Logout';
import { LOGOUT } from '../constants/paths';


const propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

class LoginPage extends Component {
  componentDidMount() {
    const { auth, history } = this.props;

    if (auth.isLoggedIn()) {
      history.push('/');
    }
  }

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={match.url} exact render={() => <Logout />} />
        <Redirect to={`/${LOGOUT}`} />
      </Switch>
    );
  }
}

LoginPage.propTypes = propTypes;
export default withAuth(LoginPage);
