import React from 'react';
import PropTypes from 'prop-types';
import Form from '../Form/Form';
import ResponsiveExpansionPanel from '../ResponsiveExpansionPanel/ResponsiveExpansionPanel';


const propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
};

const defaultProps = {
  onReset: () => {},
};

const SearchForm = ({ fields, onSubmit, onReset }) => (
  <ResponsiveExpansionPanel
    title="Search"
    collapsedText="Show Filters"
  >
    <Form
      fields={fields}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
          setSubmitting(false);
        } catch (error) {
          setSubmitting(false);
        }
      }}
      onReset={async (values, { setSubmitting, resetForm }) => {
        const resetValues = {};
        Object.keys(values).map((prop) => {
          resetValues[prop] = '';
          return resetValues;
        });
        resetForm(resetValues);
        try {
          await onReset();
          setSubmitting(false);
        } catch (error) {
          setSubmitting(false);
        }
      }}
      submitLabel="Search"
      reset
      columns={4}
      spacing={24}
    />
  </ResponsiveExpansionPanel>
);

SearchForm.propTypes = propTypes;
SearchForm.defaultProps = defaultProps;

export default SearchForm;
