import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import CsvParse from '@vtex/react-csv-parse';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import ModalForm from '../../components/ModalForm/ModalForm';


const styles = theme => ({
  orText: {
    padding: theme.spacing.unit,
    margin: theme.spacing.unit,
  },
  container: {
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    display: 'none',
  },
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onAddData: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};


const getKeys = () => (
  [
    'client',
    'email',
    'url',
    'type',
    'keywords',
  ]
);

const fields = [
  {
    id: 'client',
    name: 'client',
    label: 'Client Name',
    type: 'text',
    validateAs: 'string',
    defaultVal: '',
    required: true,
  },
  {
    id: 'email',
    name: 'email',
    label: 'Client Email',
    type: 'text',
    validateAs: 'email',
    defaultVal: '',
    required: true,
  },
  {
    id: 'url',
    name: 'url',
    label: 'Website URL',
    type: 'text',
    validateAs: 'url',
    defaultVal: '',
    required: true,
  },
];

const AddStep = ({ classes, onAddData, onError }) => (
  <Grid xs={6} sm={6} md={6} item>
    <div className={classes.container}>
      <CsvParse
        keys={getKeys()}
        onDataUploaded={onAddData}
        onError={onError}
        render={onChange => (
          <div>
            <label htmlFor="flat-button-file">
              <input
                className={classes.input}
                id="flat-button-file"
                name="button-file"
                type="file"
                onChange={onChange}
              />
              <Button variant="contained" component="span" color="secondary" className={classes.button}>
                Upload File
                <CloudUploadIcon className={classes.rightIcon} />
              </Button>
            </label>
          </div>
        )}
      />
      <Typography variant="button" color="textSecondary" className={classes.orText}>
        Or
      </Typography>
      <ModalForm
        fields={fields}
        title="Add Site"
        onSubmit={async (values) => {
          const items = [values];
          onAddData(items);
        }}
        buttonProps={{
          variant: 'outlined',
          color: 'primary',
          className: classes.button,
        }}
        buttonText="Add Manually"
      />
    </div>
  </Grid>
);


AddStep.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(AddStep);
