import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableCell as BaseTableCell } from '@material-ui/core';


const styles = theme => ({
  cell: {
    padding: '4px 14px 4px 14px',
    [theme.breakpoints.down('sm')]: {
      padding: 5,
    },
  },
  actionButtons: {
    textAlign: 'right',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  props: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.node,
  isActions: PropTypes.bool,
};

const defaultProps = {
  props: {},
  children: null,
  isActions: false,
};

const TableCell = ({
  props,
  children,
  classes,
  isActions,
}) => {
  const cellClasses = [classes.cell];

  if (isActions) {
    cellClasses.push(classes.actionButtons);
  }

  return (
    <BaseTableCell className={cellClasses.join(' ')} {...props}>
      { children }
    </BaseTableCell>
  );
};

TableCell.propTypes = propTypes;
TableCell.defaultProps = defaultProps;

export default withStyles(styles)(TableCell);
