import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AuditTable from '../containers/AuditTable/AuditTable';

const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const AuditsPage = ({ history, classes }) => (
  <div className={classes.list}>
    <AuditTable history={history} paginate search />
  </div>
);


AuditsPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(AuditsPage);
