import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Typography,
  LinearProgress,
} from '@material-ui/core';


const styles = theme => ({
  paper: {
    padding: 0,
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing.unit * 3,
  },
  progressColorPrimary: {
    backgroundColor: '#ff9930',
  },
  barColorPrimary: {
    backgroundColor: '#ffcc97',
  },
  container: {
    marginTop: theme.spacing.unit * 4,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  empty: PropTypes.bool,
  first: PropTypes.bool,
};

const defaultProps = {
  loading: true,
  empty: true,
  first: false,
};

const renderLoading = classes => (
  <LinearProgress
    classes={{
      colorPrimary: classes.colorPrimary,
      barColorPrimary: classes.progressColorPrimary,
    }}
  />
);

const renderFetching = () => (
  <Grid
    spacing={32}
    container
    alignItems="center"
    justify="center"
  >
    <Grid sm={12} md={12} item>
      <Typography variant="overline" gutterBottom>
        Fetching Data
      </Typography>
    </Grid>
  </Grid>
);

const renderEmpty = () => (
  <Grid
    spacing={32}
    container
    alignItems="center"
    justify="center"
  >
    <Grid sm={12} md={12} item>
      <Typography variant="overline" gutterBottom>
        No Data Available
      </Typography>
    </Grid>
  </Grid>
);

const DataCard = ({
  classes,
  loading,
  empty,
  title,
  children,
  first,
}) => (
  <div className={!first ? classes.container : ''}>
    <Paper className={classes.paper}>
      {loading && renderLoading(classes)}
      <div className={classes.title}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </div>
      {(empty && loading) && renderFetching(classes)}
      {(empty && !loading) && renderEmpty(classes)}
      {!empty && children }
    </Paper>
  </div>
);


DataCard.propTypes = propTypes;
DataCard.defaultProps = defaultProps;
export default withStyles(styles, { withTheme: true })(DataCard);
