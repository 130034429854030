import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const DashboardPage = ({ classes }) => (
  <div className={classes.list}>
    <h1>
      Hello There, you are logged in.
    </h1>
  </div>
);


DashboardPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(DashboardPage);
