import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TableRow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { CheckCircle, Warning, Error } from '@material-ui/icons';
import { green, red, amber } from '@material-ui/core/colors';

import { SEARCH_ENGINES, GOOGLE, GOOGLE_MOBILE } from '../../constants/seo';
import withAuth from '../../consumers/withAuth';
import api from '../../services/api';
import DataCard from '../../components/DataCard/DataCard';
import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';


const styles = theme => ({
  successText: {
    color: green[600],
  },
  warningText: {
    color: amber[600],
  },
  errorText: {
    color: red[600],
  },
  section: {
    padding: theme.spacing.unit * 3,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  url: PropTypes.string.isRequired,
};


class ProAudit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        sections: [],
      },
      loading: true,
      empty: true,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  async fetchData() {
    const { auth, url } = this.props;
    try {
      const seoReport = await api.report.getSeoReport(url, auth.getToken());
      const keywordsReport = await api.report.getKeywordsRanking(url, auth.getToken());
      const { data: { data: { sections } } } = seoReport;
      const { data: { data: { keywords } } } = keywordsReport;
      const keySection = [{ name: 'Keywords ranking', data: keywords, type: 'keywords' }];
      this.setState({
        data: {
          sections: sections.concat(keySection),
        },
        loading: false,
        empty: false,
      });
    } catch (error) {
      const { response: { status } } = error;
      this.setState({ loading: false, empty: true });
      if (status === 401) {
        auth.logout();
      }
    }
  }

  renderSection(section) {
    const { classes } = this.props;
    const getIcon = (status) => {
      let icon = (<CheckCircle className={classes.successText} />);
      switch (status) {
        case 'warning':
          icon = (<Warning className={classes.warningText} />);
          break;
        case 'error':
          icon = (<Error className={classes.errorText} />);
          break;
        default:
          break;
      }
      return icon;
    };
    const getSummary = (data) => {
      const totalPassed = data.filter(i => (i.status === 'passed')).length;
      const totalWarnings = data.filter(i => (i.status === 'warning')).length;
      const totalErrors = data.filter(i => (i.status === 'error')).length;
      return (
        <Grid
          spacing={32}
          container
          alignItems="center"
          justify="center"
        >
          <Grid sm={4} md={4} item>
            <Typography variant="h4" className={classes.successText} gutterBottom>
              {totalPassed}
            </Typography>
            <Typography variant="overline" gutterBottom>
             Passed Checks
            </Typography>
          </Grid>
          <Grid sm={4} md={4} item>
            <Typography variant="h4" className={classes.warningText} gutterBottom>
              {totalWarnings}
            </Typography>
            <Typography variant="overline" gutterBottom>
              Warnings
            </Typography>
          </Grid>
          <Grid sm={4} md={4} item>
            <Typography variant="h4" className={classes.errorText} gutterBottom>
              {totalErrors}
            </Typography>
            <Typography variant="overline" gutterBottom>
              Errors
            </Typography>
          </Grid>
        </Grid>
      );
    };
    return (
      <div className={classes.section}>
        { getSummary(section.data) }
        <List className={classes.section}>
          {section.data.map(item => (
            <ListItem alignItems="center" divider>
              <ListItemIcon>
                {getIcon(item.status)}
              </ListItemIcon>
              <ListItemText primary={(item.value !== undefined) ? `${item.value} ${item.name}` : item.name} />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }

  renderTable(section) {
    const { data } = section;
    const { classes } = this.props;
    const semGoogle = data.filter(item => item.search_engine === GOOGLE);
    const semMobile = data.filter(item => item.search_engine === GOOGLE_MOBILE);

    const getKeywordsTable = rows => (
      <DataTable
        header={[
          { id: 0, label: 'Keyword' },
          { id: 1, label: 'Position' },
        ]}
        loading={false}
        paginate={false}
        flat
        empty={rows.length === 0}
        renderRows={() => (
          rows.map(k => (
            <TableRow
              hover
              key={`${k.keyword}-${k.position}-${k.search_engine}`}
            >
              <TableCell component="th" scope="row" padding="default">
                <Typography variant="overline">
                  {k.keyword}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="overline">
                  {k.position}
                </Typography>
              </TableCell>
            </TableRow>
          )))}
      />
    );
    return (
      <Grid
        spacing={32}
        container
        alignItems="center"
        justify="center"
      >
        <Grid sm={6} md={6} item>
          <div className={classes.section}>
            <Typography variant="h5">
              {SEARCH_ENGINES[GOOGLE]}
            </Typography>
            {getKeywordsTable(semGoogle)}
          </div>
        </Grid>
        <Grid sm={6} md={6} item>
          <div className={classes.section}>
            <Typography variant="h5">
              {SEARCH_ENGINES[GOOGLE_MOBILE]}
            </Typography>
            {getKeywordsTable(semMobile)}
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { data: { sections }, loading, empty } = this.state;
    if (sections.length === 0) return null;

    return sections.map(section => (
      <DataCard
        title={section.name}
        loading={loading}
        empty={empty}
      >
        {section.type === 'keywords'
          ? this.renderTable(section) : this.renderSection(section)}
      </DataCard>
    ));
  }
}


ProAudit.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(withAuth(ProAudit));
