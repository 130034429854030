import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';

import withAuth from '../../consumers/withAuth';
import api from '../../services/api';
import DataCard from '../../components/DataCard/DataCard';


const styles = () => ({
  successText: {
    color: green[600],
  },
  errorText: {
    color: red[600],
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  url: PropTypes.string.isRequired,
};


class WpScan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        vulnerabilities: 0,
        pluginsVulns: 0,
      },
      loading: true,
      empty: true,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  async fetchData() {
    const { auth, url } = this.props;
    try {
      const wpScan = await api.report.getWpScan(url, auth.getToken());
      // eslint-disable-next-line
      const { data: { data: { vulnerabilities, plugins_vulns } } } = wpScan;
      this.setState({
        data: {
          vulnerabilities,
          pluginsVulns: plugins_vulns,
        },
        loading: false,
        empty: false,
      });
    } catch (error) {
      const { response: { status } } = error;
      this.setState({ loading: false, empty: true });
      if (status === 401) {
        auth.logout();
      }
    }
  }

  render() {
    const { data, loading, empty } = this.state;
    const { vulnerabilities, pluginsVulns } = data;
    const { classes } = this.props;
    return (
      <DataCard
        title="WordPress Scan"
        loading={loading}
        empty={empty}
      >
        <Grid
          spacing={32}
          container
          alignItems="center"
          justify="center"
        >
          <Grid sm={6} md={6} item>
            <Typography variant="h4" className={vulnerabilities > 0 ? classes.errorText : classes.successText} gutterBottom>
              {vulnerabilities}
            </Typography>
            <Typography variant="overline" gutterBottom>
              Vulnerabilities
            </Typography>
          </Grid>
          <Grid sm={6} md={6} item>
            <Typography variant="h4" className={pluginsVulns > 0 ? classes.errorText : classes.successText} gutterBottom>
              {pluginsVulns}
            </Typography>
            <Typography variant="overline" gutterBottom>
              Plugins Vulnerabilities
            </Typography>
          </Grid>
        </Grid>
      </DataCard>
    );
  }
}


WpScan.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(withAuth(WpScan));
