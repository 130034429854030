export const URLParse = (url) => {
  const parsedUrl = {};
  if (url === null || url.length === 0) {
    return parsedUrl;
  }

  const protocolIndex = url.indexOf('://');
  parsedUrl.protocol = url.substr(0, protocolIndex);

  const remainingUrl = url.substr(protocolIndex + 3, url.length);
  let domainIndex = remainingUrl.indexOf('/');
  domainIndex = domainIndex === -1 ? remainingUrl.length - 1 : domainIndex;
  parsedUrl.domain = remainingUrl.substr(0, domainIndex);
  parsedUrl.path = domainIndex === -1
    || domainIndex + 1 === remainingUrl.length
    ? null : remainingUrl.substr(domainIndex + 1, remainingUrl.length);

  const domainParts = parsedUrl.domain.split('.');
  let subdomain;
  let host;
  let tld;
  let after;
  switch (domainParts.length) {
    case 2:
      [host, tld] = domainParts;
      parsedUrl.subdomain = null;
      parsedUrl.host = host;
      parsedUrl.tld = tld;
      break;
    case 3:
      [subdomain, host, tld] = domainParts;
      parsedUrl.subdomain = subdomain;
      parsedUrl.host = host;
      parsedUrl.tld = tld;
      break;
    case 4:
      [subdomain, host, tld, after] = domainParts;
      parsedUrl.subdomain = subdomain;
      parsedUrl.host = host;
      parsedUrl.tld = `${tld}.${after}`;
      break;
    default:
      break;
  }

  parsedUrl.parent_domain = `${parsedUrl.host}.${parsedUrl.tld}`;
  return parsedUrl;
};

export const QSParse = (url, locationSearch) => {
  let queryString = url ? url.split('?')[1] : locationSearch.slice(1);

  const obj = {};
  if (queryString) {
    [queryString] = queryString.split('#');

    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i += 1) {
      const a = arr[i].split('=');

      let paramName = a[0];
      let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      if (paramName.match(/\[(\d+)?\]$/)) {
        const key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        if (paramName.match(/\[\d+\]$/)) {
          const index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          obj[key].push(paramValue);
        }
      } else if (!obj[paramName]) {
        obj[paramName] = paramValue;
      } else if (obj[paramName] && typeof obj[paramName] === 'string') {
        obj[paramName] = [obj[paramName]];
        obj[paramName].push(paramValue);
      } else {
        obj[paramName].push(paramValue);
      }
    }
  }

  return obj;
};
