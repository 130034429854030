import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  withMobileDialog,
} from '@material-ui/core';

import Form from '../Form/Form';


const propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  open: PropTypes.bool,
  buttonProps: PropTypes.oneOfType([PropTypes.object]),
  buttonText: PropTypes.string,
};

const defaultProps = {
  submitText: 'Submit',
  cancelText: 'Cancel',
  open: false,
  buttonProps: {},
  buttonText: 'Open',
};

class ModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  async handleSubmit(values) {
    try {
      const { onSubmit } = this.props;
      await onSubmit(values);
      this.handleClose();
    } catch (e) {
      this.handleClose();
    }
  }

  render() {
    const {
      fullScreen,
      fields,
      submitText,
      cancelText,
      title,
      buttonProps,
      buttonText,
    } = this.props;
    const { open } = this.state;
    return (
      <div>
        <Button
          variant="outlined"
          color="default"
          onClick={this.handleOpen}
          {...buttonProps}
        >
          {buttonText}
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <Form
              fields={fields}
              onSubmit={async (values, { setSubmitting }) => {
                await this.handleSubmit(values);
                setSubmitting(false);
              }}
              submitLabel={submitText}
            />
            <div style={{ textAlign: 'center' }}>
              <Button onClick={this.handleClose} color="inherit">
                {cancelText}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>);
  }
}

ModalForm.propTypes = propTypes;
ModalForm.defaultProps = defaultProps;
export default withMobileDialog()(ModalForm);
