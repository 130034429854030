import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = () => ({
  error: {},
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const Page404 = ({ classes }) => (
  <div className={classes.error}>
    <Typography variant="h4" gutterBottom>
      Not found!
    </Typography>
  </div>
);

Page404.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(Page404);
